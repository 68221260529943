import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import imgLogin from '../images/arbre.png';
import Header from './Header';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { FormInput, FormMultiSelect } from './form-components';
import { emailValidator, firstNameValidator, lastNameValidator } from './Validators';
import { mdpValidator, telValidator } from './Validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faInfoCircle, faLock, faSlash } from '@fortawesome/free-solid-svg-icons'
import PasswordStrengthBar from 'react-password-strength-bar';

import '../css/inscription.css';
import $ from "jquery";
import { Trad } from "../trad-lib/index.bin";
import RightSideBar from "./RightSideBar";
import { getAllCountries, allCountries } from './../Api/CountriesData'
interface Props {
    name?: any;
    value?: any;
}
interface State {
    email: string,
    firstName: string,
    lastName: string,
    gender: string,
    password: string,
    telephone: string,
    passwordConf: string,
    society: string,
    fonction: string,
    domain: string,
    address: string,
    postalCode: string,
    city: string,
    country: string,
    errors: {
        email: string,
        password: string
    },
    mediaList: any[],
    redirectInscription: boolean,
    type: boolean,
    eye: boolean,
    passwordType: string,
    countries: []
}

export default class Inscription extends Component<Props, State> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handCheckbox = this.handCheckbox.bind(this);


        this.state = {
            email: localStorage.getItem('email'),
            firstName: localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            gender: localStorage.getItem('gender'),
            password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
            passwordConf: localStorage.getItem('password') ? localStorage.getItem('password') : '',
            telephone: localStorage.getItem('telephone'),
            fonction: localStorage.getItem('fonction'),
            society: localStorage.getItem('society'),
            domain: localStorage.getItem('domain'),
            address: localStorage.getItem('address'),
            postalCode: localStorage.getItem('postalCode'),
            city: localStorage.getItem('city'),
            country: localStorage.getItem('country'),
            mediaList: [],
            errors: {
                email: '',
                password: ''
            },
            redirectInscription: false,
            passwordType: "password",
            type: false,
            eye: true,
            countries: JSON.parse(localStorage.getItem('allCountries')) ? JSON.parse(localStorage.getItem('allCountries')) : []
        };
    }
    async componentDidMount(): Promise<void> {
        const filter = {
            "filter": {
                "PRM_VALIDE": 1,
                "NoLinks": 1
            }
        }
        await axios.post(`${this.baseUrl}/country`, filter)
            .then((response: any) => {
                if (response.data.error) {
                } else {
                    const allCountries = response.data.data?.GetManyResult;
                    localStorage.setItem('allCountries', JSON.stringify(allCountries));
                    this.setState({
                        countries: allCountries
                    })
                }
            }).catch((err) => { console.log(err.message) });
    }
    createField(fieldName: string, label: string, type: any, handle: any, validator?: any, option?: any) {
        return (
            <div className="form-group registerForm">
                <label htmlFor={fieldName} className="">{Trad(label)}
                    {
                        option ?
                            <span className="optionText">{option}</span>
                            :
                            <span className="text-danger"> *</span>
                    }
                </label>
                <Field
                    id={fieldName}
                    name={fieldName}
                    className={'form-control'}
                    type={type}
                    placeholder={Trad(label)}
                    component={FormInput}
                    // defaultValue={this.state[fieldName]}
                    onChange={handle}
                    validator={validator}
                />
            </div>
        )
    }
    createRadioField(fieldName: string, label: string, type: string, handle: any, value: any) {
        return (
            <div className="row radioDiv">
                <Field
                    // id={fieldName}
                    name={fieldName}
                    className={'radiBox'}
                    type={type}
                    placeholder={Trad(label)}
                    component={FormInput}
                    onChange={handle}
                // defaultValue={this.state[fieldName]}
                />
                <label htmlFor={fieldName} className="radioLabel">{Trad(label)}</label>
            </div>
        )
    }

    handCheckbox(e: any) {
        let mediaList = this.state.mediaList;
        const { name, checked } = e.target
        if (checked) {
            this.setState({
                mediaList: [...this.state.mediaList, name]
            })
        } else {
            var index = mediaList.indexOf(name);
            if (index > -1) {
                mediaList.splice(index, 1);
                this.setState({
                    mediaList
                })
            }
        }

        console.log(this.state.mediaList);
    }
    handleChange(event: any) {
        const target = event.target as any;
        // console.log(target.name,target.value);
        this.setState({ [target.name]: target.value } as State, () => {
            localStorage.setItem(target.name, this.state[target.name])
        });
    }
    handleSubmit() {
        const url = `${this.baseUrl}/auth/signup`
        if (this.state.password !== this.state.passwordConf) {
            $('.inputMdp').css('border', '1px solid red');
            $('.messErrorMdp').css('display', 'block');
            $('.inputEmailInsc').css('border', '1px solid #BBBBBB');
            $('.messErrorEmail').css('display', 'none');
        } else {
            $('.inputMdp').css('border', '1px solid #BBBBBB');
            $('.messErrorMdp').css('display', 'none');
            const data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
                telephone: this.state.telephone,
                fonction: this.state.fonction,
                society: this.state.society,
                domain: this.state.domain,
                address: this.state.address,
                postalCode: this.state.postalCode,
                city: this.state.city,
                country: this.state.country,
                medliaList: this.state.mediaList
            };
            // console.log(data);return;
            axios
                .post(url, this.state)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.error) {
                        $('.messErrorLogin').css('display', 'block');
                    } else {
                        this.setState({
                            redirectInscription: true
                        }, () => {
                            let trad = localStorage.getItem('all-trads');
                            let currentLocal = localStorage.getItem('currentLocal');
                            localStorage.clear();
                            localStorage.setItem('all-trads', trad);
                            localStorage.setItem('currentLocal', currentLocal);
                        })
                    }
                }).catch((err) => {
                    $('.inputEmailInsc').css('border', '1px solid red');
                    $('.messErrorEmail').css('display', 'block');
                }
                );
            const dataMail = {
                email: this.state.email
            };

        }
    }
    Eye = () => {
        // console.log('h')
        if (this.state.passwordType == "password") {
            this.setState({
                passwordType: "text",
                eye: false,
                type: true,
            })
        }
        else {
            this.setState({
                passwordType: "password",
                eye: true,
                type: false,
            })
        }
    }
    setGender(event) {
        // console.log(event.target.value);
    }
    render() {
        if (this.state.redirectInscription) {
            return (<Redirect to={'/confirmInscription'} />)
        }
        if (sessionStorage.getItem('userData')) {
            return (<Redirect to={'/periode'} />)
        }
        this.state.countries.sort((a: any, b: any) => a.PAYS_LIB.localeCompare(b.PAYS_LIB));
        return (

            <div>
                <Header />
                <div className="d-flex divLogin">
                    <div className="divLeft">
                        <div className="d-flex justify-content-between">
                            <h1 className="titleInsc">{Trad("txt_register")} </h1>
                            <h5><a className="linkInscri" href="/">{Trad("txt_login_h")}</a></h5>
                        </div>

                        <div className="messErrorLogin">
                            <div>
                                <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; &nbsp;{Trad("txt_email_exist_err")}
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                email: localStorage.getItem('email'),
                                firstName: localStorage.getItem('firstName'),
                                lastName: localStorage.getItem('lastName'),
                                gender: localStorage.getItem('gender'),
                                password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                                passwordConf: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                                telephone: localStorage.getItem('telephone'),
                                fonction: localStorage.getItem('fonction'),
                                society: localStorage.getItem('society'),
                                domain: localStorage.getItem('domain'),
                                address: localStorage.getItem('address'),
                                postalCode: localStorage.getItem('postalCode'),
                                city: localStorage.getItem('city'),
                                country: localStorage.getItem('country'),
                            }}
                            onSubmit={this.handleSubmit}
                            render={(formRenderProps: any) => (
                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <div className="form-row" onChange={this.handleChange.bind(this)}>
                                            <div className="col">
                                                <input id="female" className="radiBox" type="radio" value="F" name="gender" />
                                                <label htmlFor="female" className="radioLabel">{Trad('Madame')}</label>
                                            </div>
                                            <div className="col">
                                                <input id="male" className="radiBox" type="radio" value="M" name="gender" />
                                                <label htmlFor="male" className="radioLabel">{Trad('Monsieur')}</label>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                {this.createField('lastName', 'Nom', 'text', this.handleChange, lastNameValidator)}
                                            </div>
                                            <div className="col">
                                                {this.createField('firstName', 'Prénom', 'text', this.handleChange, firstNameValidator)}
                                            </div>
                                        </div>

                                        {this.createField('email', 'Adresse e-mail', 'email', this.handleChange, emailValidator)}
                                        <div className="messErrorEmail">
                                            {Trad("txt_email_exist_err")}
                                        </div>

                                        {this.createField('telephone', 'Téléphone', 'number', this.handleChange, telValidator)}

                                        <div className="input-text k-form-field">
                                            <label htmlFor={'password'} className="">{Trad('Mot de passe')}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <Field
                                                id={'password'}
                                                name={'password'}
                                                className={`input-text form-control ${this.state.type ? "type_password" : ""}`}
                                                type={this.state.passwordType}
                                                placeholder={Trad('txt_pass_label')}
                                                component={FormInput}
                                                // defaultValue={this.state[fieldName]}
                                                onChange={this.handleChange}
                                                validator={mdpValidator}
                                            />
                                            <FontAwesomeIcon onClick={this.Eye} icon={this.state.eye ? faEye : faEyeSlash} />
                                        </div>
                                        <PasswordStrengthBar
                                            barColors={['#ddd', '#ef4836', '#747744', '#f6b44d', '#25c281']}
                                            scoreWords={['Faible', 'Faible', 'moyen', 'Bon', 'Excellent']}
                                            shortScoreWord="trop court"
                                            password={this.state.password}
                                            minLength={6}
                                        />
                                        <div className="input-text k-form-field form-group">
                                            <label htmlFor={'passwordConf'} className="">{Trad('txt_confirm_pass')}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <Field
                                                id={'passwordConf'}
                                                name={'passwordConf'}
                                                className={`input-text form-control ${this.state.type ? "type_password" : ""}`}
                                                type={this.state.passwordType}
                                                placeholder={Trad('txt_confirm_pass')}
                                                component={FormInput}
                                                // defaultValue={this.state[fieldName]}
                                                onChange={this.handleChange}
                                            />
                                            <FontAwesomeIcon onClick={this.Eye} icon={this.state.eye ? faEye : faEyeSlash} />
                                        </div>

                                        <div className="messErrorMdp">
                                            {Trad("txt_pass_match_err")}
                                        </div>

                                        <div className="form-group ">
                                            <select name="country" onChange={this.handleChange} className="form-select form-control" aria-label="Default select example">
                                                {
                                                    this.state.countries.map((counrty: any) => (
                                                        <option value={counrty.PAYS_CODE}>{counrty.PAYS_LIB.charAt(0).toUpperCase() + counrty.PAYS_LIB.slice(1).toLowerCase()}</option>
                                                    ))
                                                }
                                                {/* <option selected>Pays</option>
                                            <option value="1">France</option>
                                            <option value="2">Cote d'ivoire</option>
                                            <option value="3">Cameron</option> */}
                                            </select>
                                        </div>
                                        <div className="divInscSubmit">
                                            <p className="pInsc">{Trad("txt_condition_1")} <a className="spanInsc" href="/conditionsGenerales">{Trad("txt_condition_2")}</a></p>
                                            <button name="btnSubmit" type={'submit'} disabled={!formRenderProps.allowSubmit} className="btnSubmitLogin">{Trad("txt_register")}</button>
                                        </div>
                                    </fieldset>
                                </FormElement>
                            )}
                        />
                        <div className="divBottom">

                        </div>
                    </div>

                </div>
            </div>
        )
    }

}
