
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DataResult, State, toODataString } from '@progress/kendo-data-query';

interface UsersLoaderProps {
    dataState: State,
    onDataReceived: (products: DataResult) => void
}

export class UsersLoader extends React.Component<UsersLoaderProps> {
    baseUrl = 'https://demo.service.rfp.adwanted.com/users';
    init = { method: 'GET', accept: 'application/json', headers: {} };

    lastSuccess: string = '';
    pending: string = '';

    requestDataIfNeeded = () => {
        if (this.pending || toODataString(this.props.dataState) === this.lastSuccess) {
            return;
        }
        this.pending = toODataString(this.props.dataState);
        fetch(this.baseUrl, this.init)
            .then(response => response.json())
            .then(json => {
                const data = json.data.map((user)=>{
                    delete user._id;
                    delete user.password;
                    delete user.mediaList;
                    delete user.active;
                    delete user._v;
                    return user
                })
                this.lastSuccess = this.pending;
                this.pending = '';
                if (toODataString(this.props.dataState) === this.lastSuccess) {
                    this.props.onDataReceived.call(undefined, {
                        data: data,
                        total: data.length
                    });
                } else {
                    this.requestDataIfNeeded();
                }
            });
    }

    render() {
        this.requestDataIfNeeded();
        return this.pending && <LoadingPanel />;
    }
}


class LoadingPanel extends React.Component {
    render() {
        const loadingPanel = (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        );

        const gridContent = document && document.querySelector('.k-grid-content');
        return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
    }
}