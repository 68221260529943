import React, { Component } from 'react';
import canalStart from '../images/start.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import ButtonContinue from './ButtonContinue';
import ButtonPrev from './ButtonPrev';
import Header from './Header';
import { Redirect } from 'react-router-dom';
import moment from 'moment'

import '../css/resumePack.css';
import { Trad } from '../trad-lib/index.bin';

export default class ResumePack extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            package: []
        }
    }
    convertDate(inputFormat: any) {
        function pad(s: any) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }
    formatDate(date: string) {
        var timeStamp = date.replace("/Date(", "").split('+')[0];
        return moment(parseInt(timeStamp)).format("DD/MM/YYYY");
    }

    render() {
        if (!sessionStorage.getItem('userData')) {
            return (<Redirect to={'/'} />)
        }
        const packages = JSON.parse(localStorage.getItem("packages") || '{}');
        const packageId = localStorage.getItem("selectedPackage");
        const packInfo = packages.filter((pack: any) => {
            return pack.SNAT_ID == packageId;
        })
        const pack = packInfo[0]
        // console.log(pack)
        let packageCible = []
        if (Object.keys(pack).length) {
            const listCible = JSON.parse(localStorage.getItem("listCible") || '[]');
            if (pack.LIST_CIBLEMARKETINGS) {
                packageCible = pack.LIST_CIBLEMARKETINGS.map((cible: any) => {
                    return listCible.find((list: any) => {
                        return list.CMKT_ID == cible.SNCM_CMKT_ID;
                    });
                })
            }
        }
        const cStartDate = localStorage.getItem("cStartDate");
        const cEndDate = localStorage.getItem("cEndDate");
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div className="divTitle">
                        <p className="pResumePack">{Trad("resume")}</p>
                        <h1 className="titleResumePack">{Trad("resume_h1")}</h1>
                    </div>
                    <div className="d-flex divRes">
                        <div className="borderResume campResume">
                            <h2 className="titleResumePackCamp">{Trad("resume_h2")}</h2>
                            <div className="d-flex mt-5 dateRes">
                                <div className="divIcon"><FontAwesomeIcon icon={faCalendar} /></div>
                                <div className="divDate">
                                    <div className="labelDate">{Trad("txt_start_date")}</div>
                                    <div className="dateStyle">{this.convertDate(cStartDate)}</div>
                                </div>
                                <div className="divDate">
                                    <div className="labelDate">{Trad("txt_end_date")}</div>
                                    <div className="dateStyle">{this.convertDate(cEndDate)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="borderResume divPackResume">
                            <div className="d-flex">
                                <div className="divImgResume">
                                    <img className="imgResume" src={`data:image/png;base64,` + pack.SNAT_IMAGE} alt="" />
                                </div>
                                <div className="divTitlePack">
                                    <div className="labPack">Pack</div>
                                    <div className="labNamePack">{pack.SNAT_LIB}</div>
                                    <div className="labPricePack">{pack.SNAT_NET} €</div>
                                </div>
                            </div>
                            <div>
                                <h6 className="titleCarac">Description</h6>
                                <div className="divDesc px-2">
                                    {(pack.SNAT_PACKAGE_DESCRIPTION)?pack.SNAT_PACKAGE_DESCRIPTION:Trad("package_no_description")}
                                </div>
                            </div>
                            <div>
                                <h6 className="titleCarac">{Trad("character")}</h6>
                                <div className="d-flex">
                                    <div className="d-flex divResPack w-100">
                                        <div className="d-flex divWidth">
                                            <div className="divIcon"><FontAwesomeIcon icon={faCalendar} /></div>
                                            <div className="divDateVal">
                                                <div className="labelDate">{Trad("package_start_date")}</div>
                                                <div className="dateStyle">
                                                { pack.SNAT_DDEB_PACK ?
                                                    this.formatDate(pack.SNAT_DDEB_PACK)
                                                    : '-'
                                                }   
                                                </div>
                                            
                                            </div>
                                            <div className="divDateVal">
                                                <div className="labelDate">{Trad("package_end_date")}</div>
                                                {/* <div className="dateStyle">{this.formatDate(pack.SNAT_DFIN_PACK)}</div> */}
                                                <div className="dateStyle">
                                                { pack.SNAT_DFIN_PACK ?
                                                    this.formatDate(pack.SNAT_DFIN_PACK)
                                                    : '-'
                                                }   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="divIcon"><FontAwesomeIcon icon={faUserCircle} /></div>
                                            <div className="divAudience">
                                                <div className="labelDate">{Trad("audience")}</div>
                                                {
                                                    packageCible.map((cible: any) => (
                                                        cible ?
                                                            <div key={cible.CMKT_ID} className="dateStyle">-{cible.CMKT_LIB}</div>
                                                            : ''
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex divform divResPack w-100">
                                    <div className="d-flex divWidth">
                                        <div className="divIcon"><FontAwesomeIcon icon={faClock} /></div>
                                        <div className="divDateVal">
                                            <div className="labelDate">Format</div>
                                            <div className="dateStyle">{pack.SNAT_FORMAT}</div>
                                        </div>
                                        <div className="divDateVal">
                                            <div className="labelDate">{Trad("n_spots")}</div>
                                            <div className="dateStyle">{pack.SNAT_NBSPOTS}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="divIcon"><FontAwesomeIcon icon={faPaperclip} /></div>
                                        <div className="divDocComp">
                                            {
                                                pack.SNAT_FILE_NAME ?
                                                    <a className="linkDocComp" href={"data:application/pdf;base64," + pack.SNAT_FILE} download={pack.SNAT_FILE_NAME}>{pack.SNAT_FILE_NAME ? pack.SNAT_FILE_NAME : Trad("no_File_name")}</a>
                                                    :
                                                    <a className="linkDocComp" href="">{Trad("no_File_name")} </a>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex divBtnRfp">
                        <ButtonPrev redirect={"/home"} />
                        <div className="divEmpty"> &nbsp; </div>
                        <ButtonContinue redirect={"/package/resume/user"} />
                    </div>
                </div>
            </div>
        )
    }
}