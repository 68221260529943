import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trad} from '../trad-lib/index.bin';
import Header from './Header';
import "react-datepicker/dist/react-datepicker.css";
import '../css/periode.css';
import Carousel from 'react-multi-carousel';
import axios from 'axios';
import { count } from 'console';
export default class Country extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false,
            countries: localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : [] 
        };
    }
    handleChangeStart = (country: any) => {
        this.setState({
            country_selected:country.PAYS_ID,
            emailBrief:country.PAYS_EMAIL_BRIEF,
            redirect: true
        },()=>{
            if(localStorage.getItem('country_id_selected') !== country.PAYS_ID){
                localStorage.setItem("country_id_selected",country.PAYS_ID)
                localStorage.setItem("emailBrief",country.PAYS_EMAIL_BRIEF)
                localStorage.removeItem('packages')
            }
        });
    }
    componentDidMount() {
        const filter =         {
            "filter": {
                "PRM_PUBLIABLE_BRIEF": 1,
                "PRM_VALIDE": 1,
                "NoLinks": 1
            }
        }
        axios
              .post(`${this.baseUrl}/country`, filter)
              .then((response: any) => {
                if(!response.data.data.GetManyResult){
                    
                }else{
                    const data = response.data.data.GetManyResult;
                    const countries = data.filter((country)=> {
                        if(country.PAYS_CODE == 'CMR'){
                            country.flag = "../images/cameroon.png";
                        }else if(country.PAYS_CODE == 'CIV'){
                            country.flag = "../images/ivorycost.png";
                        }else if(country.PAYS_CODE == 'CHN'){
                            country.flag = "../images/china.png";
                        }
                    return country;
                        
                    }) 
                 
                    // console.log(countries)
                    localStorage.setItem('countries',JSON.stringify(countries));
                      this.setState({
                        countries: JSON.parse(localStorage.getItem('countries') || '[]')
                      });
                }
            }).catch((err) => console.log(err.message));

        localStorage.setItem('stepUSer', JSON.stringify(0));
    }
    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 1900 },
              items: 4
            },
            desktop: {
              breakpoint: { max: 1900, min: 1424 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1424, min: 950 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 950, min: 0 },
              items: 1
            }
        };
        if (!sessionStorage.getItem('userData')) {
            return (<Redirect to={'/'} />)
        }
        if(this.state.redirect){
            return (<Redirect to={{pathname:"/periode"}}/>)
        }
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div>
                        <p className="pPeriode">{Trad("txt_country")}</p>
                        <h1 className="titlePeriode">{Trad("txt_country_h1")}</h1>
                    </div>

                    <div className="divCarou">
                        <Carousel responsive={responsive}>
                            {
                                this.state.countries.map((country:any, i:number)=>(
                                    <div className="divCard" key={i} >
                                        <div className="card text-center cardCust shadow">
                                            <div className="divImgCard">
                                                <img className="card-img-top" id="packImage" src={country.flag} alt=""/>
                                            </div>
                                            <div className="card-body">
                                                <h2 title={country.PAYS_LIB} className="card-title font-weight-bold text-truncate">{country.PAYS_LIB}</h2>

                                                <div>
                                                    <button onClick={()=> this.handleChangeStart(country)} className="btn btnCountry font-weight-bold">{Trad("txt_button_country")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            </Carousel>
                    </div>
                </div>
            </div>
        )
    }
}