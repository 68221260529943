import React, { Component } from 'react';
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';

import '../css/validation.css';
import { Trad } from '../trad-lib/index.bin';

export default class ResumeUser extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.onClickBtnNew = this.onClickBtnNew.bind(this);
        this.state = {
            redirect: false,
            redirectPath: "",
        }
    }
    onClickBtnNew(){
        this.setState({
            redirect: true,
            redirectPath: "/country"
        });
    }
    componentDidMount(){
            let trad = localStorage.getItem('all-trads');
            let currentLocal = localStorage.getItem('currentLocal');
            localStorage.clear();
            localStorage.setItem('all-trads',trad);
            localStorage.setItem('currentLocal',currentLocal);
    }
    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        if(this.state.redirect){
            return (<Redirect to={this.state.redirectPath}/>)
        }
        return (
            <div>
                <Header />
                <div className="paddingBody divValid">
                    <div>
                        <FontAwesomeIcon className="iconValid" icon={faCheckCircle} />
                    </div>
                    <div className="divTitleValid">
                        <h1 className="titleVal">
                            {Trad("Votre demande a été transmise aux commerciaux.")}
                        </h1>
                        <p className="pVal">
                            {Trad("Nous vous recontacterons dans les meilleurs délais.")}
                        </p>
                    </div>
                    <div className="pb-5">
                    <button name="btnNew" onClick={this.onClickBtnNew} className="btnNew">
                        {/* {Trad("create_new_campaign_button")} */}
                        Faire une nouvelle demande
                    </button>
                    </div>
                </div>
            </div>
        )
    }
}