import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { UsersLoader } from './UsersLoader';
import { DataResult, State } from '@progress/kendo-data-query';
import { CSVDownload,CSVLink } from "react-csv";
import { Redirect } from 'react-router-dom';

interface AppState {
  users: { data: [], total: number },
  dataState: State
  skip: number,
  exporting: boolean
}

const gridPDFExport: any = React.createRef()

export default class Users extends React.Component {
    state: AppState = {
        users: { data: [], total: 0 },
        dataState: { take: 10, skip: 0 },
        skip: 0,
        exporting: false
    };
    total: number = this.state.users.data.length;
    pageSize: number = 10;
    render() {
      if (!sessionStorage.getItem('userData')) {
        return (<Redirect to={'/'} />)
    }
        const grid = (
          <Grid
            data={this.state.users.data}
            // pageable={true}
            onPageChange={this.pageChange}
            // pageSize={this.pageSize}
            total={this.total}
            skip={this.state.skip}
            >
            <GridToolbar>
              <CSVLink className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" filename={"contacts.csv"} data={this.state.users.data}>Export CSV</CSVLink>
                <CSVDownload data={this.state.users.data} target="_blank" />
            </GridToolbar>
            <Column field="firstName" title="Nom" width="300px" />
            <Column field="lastName" title="Prenom" width="300px" />
            <Column field="email" title="Email" width="300px" />
            <Column field="society" title="Société" width="300px" />
            <Column field="telephone" title="Téléphone" width="300px" />
            <Column field="domain" title="Fonction" width="300px" />
          </Grid>
          
          );
          return (
              <div>
                {grid}
                <GridPDFExport
                ref={gridPDFExport}
                    >
                {grid}
                </GridPDFExport>
                <UsersLoader
                dataState={this.state.dataState}
                onDataReceived={this.dataReceived}
                />
          </div>
        );
    }

    pageChange = (event: GridPageChangeEvent) => {
        this.setState(this.createState(event.page.skip, event.page.take));
    }

    createState(skip: number, take: number) {
        return {
            data: this.state.users.data.slice(0, this.pageSize),
            skip: skip
        };
    }

    exportPDF = () => {
        gridPDFExport.current.save(this.state.users, this.pdfExportDone);

        this.setState({ exporting: true });
    }

    pdfExportDone = () => {
        this.setState({ exporting: false });
    }
    dataStateChange = (e: GridDataStateChangeEvent) => {
        this.setState({
          ...this.state,
          dataState: e.dataState,
        });
      };
    
      dataReceived = (users: DataResult) => {
        this.setState({
          ...this.state,
          users: users,
        });
      };
    
}
