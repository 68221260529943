import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'

import '../css/modalDetailPack.css';
import { Trad } from '../trad-lib/index.bin';

export default class ModalDetailPack extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    constructor(props: any) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitModal = this.handleSubmitModal.bind(this);
        this.state = {
            redirect: false,
            redirectPath: "",
        };
    }
    handleClose() {
        this.props.onCurrentModalShow();
    }
    handleSubmitModal(data: any) {

    }
    onClickPack(pack: any) {
        //  add selected package to the localStorage
        pack.cStartDate = localStorage.getItem("cStartDate");
        pack.cEndDate = localStorage.getItem("cStartDate");
        localStorage.setItem("selectedPackage", pack._id)
        this.setState({
            redirect: true,
            redirectPath: "/package/resume"
        }, () => {
            delete pack._id;
            axios
                .post(`${this.baseUrl}/rfi`, pack)
                .then((response) => {
                    if (response.data.error) {
                        console.log(response)
                    } else {
                        localStorage.setItem('rfi', JSON.stringify(response.data.data));
                    }

                }).catch((err) => console.log(err.message));

        });
    }
    formatDate(date: string) {
        var timeStamp = date.replace("/Date(", "").split('+')[0];
        return moment(parseInt(timeStamp)).format("DD/MM/YYYY");
    }
    render() {
        let packageCible = []
        if (Object.keys(this.props.pack).length) {
            const listCible = JSON.parse(localStorage.getItem("listCible") || '[]');
            if (this.props.pack.LIST_CIBLEMARKETINGS) {
                packageCible = this.props.pack.LIST_CIBLEMARKETINGS.map((cible: any) => {
                    return listCible.find((list: any) => {
                        return list.CMKT_ID == cible.SNCM_CMKT_ID;
                    });
                })
            }
        }
        if (this.state.redirect) {
            return (<Redirect to={{ pathname: this.state.redirectPath }} />)
        }
        if (Object.entries(this.props.pack).length === 0) {
            return (
                <div>

                </div>
            )
        }
        return (
            <div>
                <Modal centered={true} show={this.props.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="mx-4">
                        <div className="d-flex divImageModalDis">
                            <div className="divImgModalPack">
                                <img className="imgModalPack" src={`data:image/png;base64,` + this.props.pack.SNAT_IMAGE} alt="" />
                            </div>
                            <div className="divNameModalDet">
                                <h1 className="titleModalPack"><span className="colorSpan"></span>{this.props.pack.SNAT_LIB}</h1>
                                <div className="divBudModal">
                                    {this.props.pack.SNAT_NET} €
                                </div>
                                <div className="d-flex divBtnModalChoi">
                                    <div>
                                        <select className="selectModalPack" name="nbPack" id="nbPack">
                                            <option value="1">1</option>
                                        </select>
                                    </div>
                                    <div>
                                        <button onClick={() => this.props.onClickPack(this.props.pack)} className="btnChoiOffMod">{Trad("txt_button_backage")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="descriTitleModalPack">
                            Description
                        </div>
                        <div className="descriModalPack">
                            <div className="divDesc px-2">
                                {(this.props.pack.SNAT_PACKAGE_DESCRIPTION) ? this.props.pack.SNAT_PACKAGE_DESCRIPTION : Trad("package_no_description")}
                            </div>
                        </div>
                        <div className="descriTitleModalPack">
                            Caractéristiques
                        </div>
                        <div className="d-flex mt-3 mb-5 divCarac">
                            <div className="w-50 divWiMod">
                                <div className="d-flex mb-4">
                                    <div className="divIcon"><FontAwesomeIcon icon={faCalendar} /></div>
                                    <div className="divDateVal">
                                        <div className="labelDate">{Trad("package_start_date")}</div>
                                        <div className="dateStyle">
                                        { this.props.pack.SNAT_DDEB_PACK ?
                                            this.formatDate(this.props.pack.SNAT_DDEB_PACK)
                                                : '-'
                                            }
                                            </div>
                                    </div>
                                    <div className="divDateVal">
                                        <div className="labelDate">{Trad("package_end_date")}</div>
                                            <div className="dateStyle">
                                        
                                                { this.props.pack.SNAT_DFIN_PACK ?
                                                    this.formatDate(this.props.pack.SNAT_DFIN_PACK)
                                                        : '-'
                                                }
                                            </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="divIcon"><FontAwesomeIcon icon={faClock} /></div>
                                    <div className="divDateVal">
                                        <div className="labelDate">Format</div>
                                        <div className="dateStyle">{this.props.pack.SNAT_FORMAT}</div>
                                    </div>
                                    <div className="divDateVal">
                                        <div className="labelDate">{Trad("n_spots")}</div>
                                        <div className="dateStyle">{this.props.pack.SNAT_NBSPOTS}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-50 pl-5 divAudienMod divWiMod">
                                <div className="d-flex mb-4">
                                    <div className="divIcon"><FontAwesomeIcon icon={faUserCircle} /></div>
                                    <div className="divAudience">
                                        <div className="labelDate">{Trad("audience")}</div>
                                        {
                                            packageCible.map((cible: any) => (
                                                cible ?
                                                    <div key={cible.CMKT_ID} className="dateStyle">-{cible.CMKT_LIB}</div>
                                                    : ''
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="divIcon"><FontAwesomeIcon icon={faPaperclip} /></div>
                                    <div className="divDocComp">
                                        {
                                            this.props.pack.SNAT_FILE_NAME ?
                                                <a className="linkDocComp" href={"data:application/pdf;base64," + this.props.pack.SNAT_FILE} download={this.props.pack.SNAT_FILE_NAME}>{this.props.pack.SNAT_FILE_NAME ? this.props.pack.SNAT_FILE_NAME : Trad("no_File_name")}</a>
                                                :
                                                <a className="linkDocComp" href="">{Trad("no_File_name")} </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}