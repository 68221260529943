import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../css/home.css';
import { Redirect } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import RfiContext from './RfiContext';
import ModalDetailPack from './ModalDetailPack';
import { Trad, TradProvider } from '../trad-lib/index.bin'
import { Client } from '../trad-lib/client.bin';
import XMLParser from 'react-xml-parser';
import moment from 'moment'
import ReactLoader from './ReactLoader';
import devisesMediaApi from '../utils/devisesMediaApi.json'
import ButtonPrev from './ButtonPrev';
import ButtonContinue from './ButtonContinue';

interface MyProps {};
interface MyState { 
    packages: [],
    redirect: Boolean,
    redirectPath: string,
    packModal: object,
    showModal: boolean,
    isLoading
};
export default class Home extends Component<MyProps, MyState> {
    static contextType = RfiContext;

    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    constructor(props:any){
        super(props)
        this.state = {
            packages : [],
            redirect: false,
            redirectPath: "",
            packModal: {},
            showModal: false,
            isLoading:true
        }
        this.onClickPack = this.onClickPack.bind(this);
        this.onClickCampagne = this.onClickCampagne.bind(this);
        this.onClickModalDetail = this.onClickModalDetail.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
    }
    createRFI(pack:any){
        let rfi = JSON.parse(localStorage.getItem("rfi") || '[]');
        // const supportList = pack.LIST_QUOTAS.map((support:any)=>{
        //     return support.SNQO_STCK_ID;
        // });
        const data = {
            "filter": {
                "PRM_LIST_ANTN_ID": "",
                // "PRM_LIB_LIKE": "%RM%",
                // "PRM_LIST_ID": supportList,
                "NoLinks": 1
            }
        }
        axios
        .post(`${this.baseUrl}/package/supports`,data)
        .then((response) => {
          if(response.data.error){
            console.log(response)
          }else{
            //   console.log(response.data.data.GetManyResult)
            localStorage.setItem('supports',JSON.stringify(response.data.data.GetManyResult));
          }

         }).catch((err) => console.log(err.message));


         rfi = {...rfi, 
            name: pack.SNAT_LIB, 
            budget:pack.SNAT_NET,
            // titles: supportList,
            properties: {
            img : pack.SNAT_IMAGE,
            spots : pack.SNAT_NBSPOTS,
            devise: this.getDeviseAbvr(pack.SNAT_DEVI_ID),
            format : pack.SNAT_FORMAT,
            startDate : pack.SNAT_DDEB_PACK ? this.formatDate(pack.SNAT_DDEB_PACK) : '-',
            endDate :  pack.SNAT_DFIN_PACK ? this.formatDate( pack.SNAT_DFIN_PACK) : '-'
            }
        }
        
        localStorage.setItem('rfi',JSON.stringify(rfi));
        // console.log(rfi)
        return rfi;
    }
    numberFormat = (value,currency)=>{
        currency = currency.length > 0 ? currency : 'XAF';
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: currency
        }).format(value);
    }
    getDeviseAbvr(deviseId?){
        const data = devisesMediaApi.devises;
        return data.filter((d)=> d.DEVI_ID === deviseId).map((res) => res.DEVI_CODE_ISO)
    }
    formatDate(date:string){
        var timeStamp = date.replace("/Date(", "").split('+')[0];        
        return moment(parseInt(timeStamp)).format("DD/MM/YYYY");
    }
    onClickPack(pack:any){
        this.createRFI(pack);
        localStorage.setItem("selectedPackage",pack.SNAT_ID)
        this.setState({
            redirect: true,
            redirectPath: "/package/resume"
        });
    }
    onClickCampagne(){         
        this.setState({
            redirect: true,
            redirectPath: "/campagne/supports"
        });
    }
    onClickModalDetail(pack){
        this.setState({
            showModal: true,
            packModal: pack
        });
    }
    handleModalShow(){
        this.setState({
            showModal: false
        });
    }
    async componentDidMount(){
        const packages = JSON.parse(localStorage.getItem("packages") || '{}');
        if(packages.length > 0 ){
            this.setState({
                packages: JSON.parse(localStorage.getItem('packages') || '[]')
              });
        }else{
            const cStartDate = new Date(localStorage.getItem("cStartDate")).getTime() ;
            const cEndDate =  new Date(localStorage.getItem("cEndDate")).getTime() 
            const cibles = JSON.parse(localStorage.getItem('cibleSelect') || '[]')
            const country_id_selected = JSON.parse(localStorage.getItem('country_id_selected') || '[3,11]')
            const listSupport = {
                "filter": {
                    "PRM_LIST_ANTN_ID": "",
                    "PRM_LIB_LIKE": "%%",
                    "PRM_LIST_ID": null,
                    "PRM_LIST_PAYS_ID": country_id_selected,
                    "PRM_PUBLIABLE_BRIEF": 1,
                    "PRM_VALIDE": 1,
                    "NoLinks": 0
                }
            }
            const list_support_filtered = [];

            await axios
            .post(`${this.baseUrl}/support/canal`,listSupport)
            .then((response: any) => {
                if(response.data.error){
                    // console.log(response)
                }else{
                    const resp = response.data.data.GetManyResult;
                    // console.log(resp);
                    resp.map((support)=>{
                        list_support_filtered.push(support.STCK_ID)
                    })
                    
                }
                
            }).catch((err) => console.log(err.message)); 
            // console.log(cEndDate)
            const data = {
                "filter": {
                    "PRM_DDEB_PACK": cStartDate ? `/Date(${cStartDate})/` : '',
                    "PRM_DFIN_PACK": cEndDate ? `/Date(${cEndDate})/` : '',
                    "PRM_LIST_PAYS_ID": country_id_selected,
                    "PRM_LIST_CMKT_ID": cibles.toString(),
                    // "PRM_LIST_STCK_ID": list_support_filtered.toString(),
                    "PRM_PMP_OFFRE": 1,
                    "PRM_VALIDE": 1,
                    "Portail": 1,
                    "NoLinks": 0
                }
            }
            let o = this.removeEmpty(data);
            // console.log(o)
            await axios
            .post(`${this.baseUrl}/package/filtered`,data)
            .then((response: any) => {
                if(response.data.error){
                    // console.log(response)
                }else{
                    const packages = response.data.data.GetManyResult;   
                    this.setState({isLoading:false}) 
                    // console.log(response);  
                    this.getPackageImages(packages);
                    this.getPackageFile(packages);
                    this.getPackageDescription(packages);
                    localStorage.setItem('packages',JSON.stringify(packages));
                    this.setState({
                      packages: JSON.parse(localStorage.getItem('packages') || '[]')
                    });
                }
                
            }).catch((err) => console.log(err.message)); 
        }
    }
    removeEmpty = (obj) => {
        Object.keys(obj).forEach(k =>
          (obj[k] && typeof obj[k] === 'object') && this.removeEmpty(obj[k]) ||
          (!obj[k] && obj[k] !== undefined) && delete obj[k]
        );
        return obj;
      };
    async getPackageImages(packages){
        packages.map((pack:any)=>{
            var data = {
                "filter": {
                  "PRM_ARCH_TABLE": "NCFA4150",
                    "PRM_ARCH_TYPE": "V",
                    "PRM_ARCH_ELEM_ID": pack.SNAT_ID
                }
              }
               axios
              .post(`${this.baseUrl}/package/getImage`, data)
              .then((response: any) => {
                  pack.SNAT_TEST = "TEST"
                  if(response.data.data.GetManyResult[0]){
                    let stringImage = this.arrayBufferToBase64(response.data.data.GetManyResult[0].ARCH_DOC);
                    // console.log(stringImage)
                    pack.SNAT_IMAGE = stringImage;
                }else{
                    // pack.SNAT_IMAGE =  "images/business.png";
                }
                localStorage.setItem('packages',JSON.stringify(packages));
                  this.setState({
                    packages: JSON.parse(localStorage.getItem('packages') || '[]')
                  });
            }).catch((err) => console.log(err.message));
        })
    }
    async getPackageFile(packages){
        packages.map((pack:any)=>{
            var data = {
                "filter": {
                  "PRM_ARCH_TABLE": "NCFA4150",
                    "PRM_ARCH_TYPE": "P",
                    "PRM_ARCH_ELEM_ID": pack.SNAT_ID
                    // Pour l'instant aucun fichier donc je met en dur le 176 pour un pdf (dispo sur la machine 9505 seulement)
                    // "PRM_ARCH_ELEM_ID": 176
                }
              }
               axios
              .post(`${this.baseUrl}/package/getFile`, data)
              .then((response: any) => {
                  if(response.data.data.GetManyResult[0]){
                    let stringFile = this.arrayBufferToBase64(response.data.data.GetManyResult[0].ARCH_DOC);
                    let stringFile_Name = response.data.data.GetManyResult[0].ARCH_CHAR;
                    // console.log(stringImage)
                    pack.SNAT_FILE = stringFile;
                    pack.SNAT_FILE_NAME = stringFile_Name;
                }else{
                    // pack.SNAT_IMAGE =  "images/business.png";
                }
                localStorage.setItem('packages',JSON.stringify(packages));
                  this.setState({
                    packages: JSON.parse(localStorage.getItem('packages') || '[]')
                  });
            }).catch((err) => console.log(err.message));
        })
    }
    getPackageDescription(packages){
         packages.map((pack:any)=>{
            var data = {
                "filter": {
                    "PRM_COMM_ELEM_ID": pack.SNAT_ID,
                    // "PRM_COMM_ELEM_ID": 176,
                    "PRM_COMM_TABLE": "NCFA4150",
                    "NoLinks": 0
                }
              }
               axios
              .post(`${this.baseUrl}/package/getDescription`, data)
              .then((response: any) => {
                  if(response.data.data.GetManyResult[0]){
                    const test = this.stripRtf(response.data.data.GetManyResult[0].COMM_COMM)
                    pack.SNAT_PACKAGE_DESCRIPTION = test;
                }else{
                    // pack.SNAT_IMAGE =  "images/business.png";
                }
                localStorage.setItem('packages',JSON.stringify(packages));
                  this.setState({
                    packages: JSON.parse(localStorage.getItem('packages') || '[]')
                  });
            }).catch((err) => console.log(err.message));
        })
    }

    stripRtf(str){
        var basicRtfPattern = /\{\*?\\[^{}]+;}|[{}]|\\[A-Za-z]+\n?(?:-?\d+)?[ ]?/g;
        var newLineSlashesPattern = /\\\n/g;
        var ctrlCharPattern = /\n\\f[0-9]\s/g;
    
        //Remove RTF Formatting, replace RTF new lines with real line breaks, and remove whitespace
        return str
            .replace(ctrlCharPattern, "")
            .replace(basicRtfPattern, "")
            .replace(newLineSlashesPattern, "\n")
            .trim();
    }
    arrayBufferToBase64( buffer:any ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }
    render() {

        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        if(this.state.redirect){
            return (<Redirect to={{pathname:this.state.redirectPath}} />)
        }
        const packages = this.state.packages;
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 1900 },
              items: 4
            },
            desktop: {
              breakpoint: { max: 1900, min: 1424 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1424, min: 950 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 950, min: 0 },
              items: 1
            }
        };

        return (
            <div>
                <ModalDetailPack onClickPack={this.onClickPack}  pack={this.state.packModal} showModal={this.state.showModal} onCurrentModalShow={this.handleModalShow} />
                <Header />
                <div className="divCamp paddingBody">
                    <h1 className="font-weight-bold titleCamp">{Trad("Nos offres dans votre pays")}</h1>
                    {/* <button name="btnCamp" onClick={this.onClickCampagne} className="btn btnCamp">{Trad("describe_button")}</button> */}
                </div>
                <div className="divPack paddingBody">

                    {                   
                        packages.length === 0 && this.state.isLoading ?
                        <ReactLoader />
                        : packages.length === 0 && !this.state.isLoading ?
                        <h1 className="font-weight-bold titlePack">{Trad("Pas de package disponible")}</h1>
                        :''
                    }
                    <div className="divCarou">
                        <Carousel responsive={responsive}>
                            {
                                packages.map((pack:any, i:number)=>(
                                    <div className="divCard" key={i} >
                                        <div className="card text-center cardCust shadow">
                                            <div className="divImgCard">
                                            { pack.SNAT_IMAGE ? 
                                                <img className="card-img-top" id="packImage" src={`data:image/png;base64,`+ pack.SNAT_IMAGE} alt=""/>
                                            :
                                            <img className="card-img-top" id="packImage" src="images/business.png" alt=""/>
                                            }
                                            </div>
                                            <div className="card-body">
                                                <h2 title={pack.SNAT_LIB} className="card-title font-weight-bold text-truncate">{pack.SNAT_LIB}</h2>
                                                <p className="card-text font-weight-bold">{this.numberFormat(pack.SNAT_NET,this.getDeviseAbvr(pack.SNAT_DEVI_ID))} </p>
                                                <p className="pSpots">{pack.SNAT_NBSPOTS} spots</p>
                                                <div>
                                                    <button onClick={ ()=> this.onClickPack(pack)} className="btn btnChoiOff font-weight-bold">{Trad("txt_button_backage")}</button>
                                                </div>
                                                <div className="divLinkDetail font-weight-bold">
                                                    <button className="linkDetail" onClick={() => this.onClickModalDetail(pack)}>{Trad("txt_link_package_detail")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Carousel>
                        <div className="d-flex divBtnRfp">
                        <ButtonPrev redirect={"/audience"} />
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}