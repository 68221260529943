import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import imgLogin from '../images/arbre.png';
import Header from './Header';

import '../css/confirmInscription.css';
import axios from "axios";

export default class RegistrationConfirmed extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props: any) {
        super(props);
        this.onClickStart = this.onClickStart.bind(this);
        this.state = {
            redirect: false,
            path: '/',
            token: (props.match.params as any).token
        };
    }
    componentDidMount() {
        axios
            .get(`${this.baseUrl}/auth/verifyToken/${this.state.token}`)
            .then((response) => {
                console.log(response.data)

                if (response.data.error) {
                    console.log(response.data)
                } else {
                    axios
                        .get(`${this.baseUrl}/auth/verify/${this.state.token}`)
                        .then((response) => {
                            console.log(response.data)
                        }).catch((err) => {
                            this.setState({
                                redirect: true,
                                path: '/404'
                            })
                        });
                }
            }).catch((err) => {
                console.log(err)
                this.setState({
                    redirect: true,
                    path: '/404'
                })
            }
            );
    }
    onClickStart() {
        this.setState({
            redirect: true,
            path: '/'
        });
    }
    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.path} />)
        }
        return (
            <div>
                <Header />
                <div className="d-flex divLogin">
                    <div className="divLeft">
                        <h1 className="titleConfirmInsc">Votre inscription a été validé.</h1>

                        <div className="divBtnConfirmInsc">
                            <button name="btnStart" onClick={this.onClickStart} className="btnSuiv">Login</button>
                        </div>
                    </div>
                    <div className="divRight">
                        <img className="imgLogin" src={imgLogin} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}
