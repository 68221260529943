import React, { Component } from 'react';
import ButtonContinue from './ButtonContinue';
import { DatePicker, Calendar } from '@progress/kendo-react-dateinputs';
import { IntlProvider, load } from '@progress/kendo-react-intl';
import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { Redirect } from 'react-router-dom';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { Trad ,  TradProvider } from '../trad-lib/index.bin';
import Header from './Header';

import "react-datepicker/dist/react-datepicker.css";
import '../css/periode.css';
import ButtonPrev from './ButtonPrev';

load(
    likelySubtags,
    currencyData,
    weekData,
    frLocalCurrency,
    frNumbers,
    frCaGregorian,
    frDateFields
);

export default class Periode extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
    constructor(props: any) {
        super(props);
        let dateMoreOneWeek = new Date();
        dateMoreOneWeek.setDate(dateMoreOneWeek.getDate()+7);
        this.state = {
            dateStart: localStorage.getItem("cStartDate") ? new Date(localStorage.getItem("cStartDate") || '[]')  : dateMoreOneWeek,
            dateEnd: localStorage.getItem("cEndDate") ? new Date(localStorage.getItem("cEndDate") || '[]') : null,
            btnDisabled: false,
        };
    }
    handleChangeStart = (event: any) => {
        this.setState({
            dateStart: event.target.value
        },()=>{
            localStorage.setItem("cStartDate",event.target.value)
            localStorage.removeItem('packages')
        });
        if(localStorage.getItem("cEndDate")){
            this.setState({
                btnDisabled: false
            });
        }
    }
    handleChangeEnd = (event: any) => {
        if(event.target.value == null){
            this.setState({
                dateEnd: null
            },()=>{
                localStorage.removeItem("cEndDate")
            });
            return
        }
        // console.log(event.target.value)
        this.setState({
            dateEnd: event.target.value
        },()=>{
            localStorage.setItem("cEndDate",event.target.value)
        });
        if(localStorage.getItem("cStartDate")){
            this.setState({
                btnDisabled: false
            });
        }
        localStorage.removeItem('packages')
    }
    CustomToggleButton = (props: ButtonProps) => {
        return (
          <Button {...props} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-link">
            {props.children}
            
          </Button>
        );
      };
    componentDidMount(){
        localStorage.setItem("cStartDate",this.state.dateStart);
        if(localStorage.getItem("cStartDate") && localStorage.getItem("cEndDate")){
            this.setState({
                btnDisabled: false
            });
        }
        // localStorage.setItem('stepUSer',JSON.stringify(0));
    }
    render() {
        const locale = localStorage.getItem("currentLocal") ? localStorage.getItem("currentLocal") : 'fr'
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        let dateMoreOneWeek = new Date();
        dateMoreOneWeek.setDate(dateMoreOneWeek.getDate()+7);
        const customCalendar = (props: any) => {
            return (
                <IntlProvider locale={locale}>
                    <Calendar {...props}
                        views={1}
                        navigation={false}
                    />
                </IntlProvider>
            )
        }
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div>
                        <p className="pPeriode">{Trad("txt_period")}</p>
                        <h1 className="titlePeriode">{Trad("txt_period_h1")}</h1>
                    </div>
                    <div className="d-flex">
                        <div className="divWidthPer">
                            <div className="labInputDate">{Trad("txt_start_date")}</div>
                            <div className="divDatePeriode">
                                <IntlProvider locale={locale}>
                                    <DatePicker calendar={customCalendar}
                                                onChange={this.handleChangeStart}
                                                toggleButton={this.CustomToggleButton}
                                                value={this.state.dateStart}
                                                min={new Date()}
                                                max={this.state.dateEnd!==null?this.state.dateEnd:new Date("01/01/6000")}
                                                formatPlaceholder={{ year: 'aaaa', month: 'mm', day: 'jj' }}
                                                />
                                </IntlProvider>
                            </div>
                        </div>
                        <div className="divWidthPer">
                            <div className="labInputDate">{Trad("txt_end_date")}</div>
                            <div className="divDatePeriode">
                                <IntlProvider locale={locale}>
                                    <DatePicker calendar={customCalendar}
                                                onChange={this.handleChangeEnd}
                                                toggleButton={this.CustomToggleButton}
                                                value={this.state.dateEnd}
                                                min={this.state.dateStart}
                                                formatPlaceholder={{ year: 'aaaa', month: 'mm', day: 'jj' }}
                                                />
                                </IntlProvider>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex divBtnRfp">
                        <ButtonPrev redirect={"/country"}  />
                        <ButtonContinue redirect={"/audience"} skip={this.state.dateEnd ? false : true} onDisabled={this.state.btnDisabled} />
                    </div>
                </div>
            </div>
        )
    }
}