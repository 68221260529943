import axios from "axios";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import imgLogin from '../images/arbre.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Trad } from '../trad-lib/index.bin'
import PasswordStrengthBar from 'react-password-strength-bar';
import {FormInput} from './form-components';
import {mdpValidator} from './Validators';
import $ from "jquery";
import Header from './Header';

import '../css/login.css';
import { Form,Field, FormElement } from "@progress/kendo-react-form";

interface Props {
}
interface State {
    password:string,
    passwordConf:string,
    isSubmitting: boolean,
    token:string,
    redirect: boolean
    path:string,

}
export default class ResetPassword extends Component<Props, State> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props:any){
        super(props)
        this.state = { 
            password:'',
            passwordConf:'',
            isSubmitting: false,
            redirect: false,
            path:'',
            token:(props.match.params as any ).token
        }
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {
        axios
        .get(`${this.baseUrl}/auth/verifyToken/${this.state.token}`)
        .then((response) => {
        if(response.data.error){
            $('.messErrorLogin').css('display', 'block');
        }else{
            // console.log(response.data)
        }
        }).catch((err) => 
            {
                this.setState({
                    redirect: true,
                    path:'/404'
                })
            }
            // $('.messErrorLogin').css('display', 'block')
        ); 
    }
    handleChange(event: any) {
        const target = event.target as any;
        this.setState({ [target.name]: target.value } as State);
    }
    handleSubmit = (event:any) => {
        const url = `${this.baseUrl}/auth/canal/updatePassword`
        this.setState({ isSubmitting: true });
        const state = this.state;
        if(this.state.password !== this.state.passwordConf){
            $('.inputMdp').css('border', '1px solid red');
            $('.messErrorMdp').css('display', 'block');
            return;
        } else {
            $('.inputMdp').css('border', '1px solid #BBBBBB');
            $('.messErrorMdp').css('display', 'none');
            axios
            .put(url,state)
            .then((response) => {
            if(response.data.error){
                // console.log(response.data)
            }else{
                // console.log(response.data)
                this.setState({
                    redirect: true,
                    path:'/passwordchanged'
                });
            }
            }).catch((err) => 
                $('.messErrorLogin').css('display', 'block').text('Something went wrong!')
            ); 
        }

      };
      createField(fieldName:string,label:string,type:string,handle:any,validator?:any,option?:any){
        return (
            <div className="form-group registerForm">
                <label htmlFor={fieldName} className="">{Trad(label)}<span className="optionText"> {option}</span></label>
                 <Field
                    id={fieldName}
                    name={fieldName}
                    className={'form-control'}
                    type={type}
                    placeholder={Trad(label)}
                    component={FormInput}
                    onChange={handle}
                    validator={validator}
                />
            </div>
            )
    }
    render() {
        if (this.state.redirect){
            return (<Redirect to={this.state.path}/>)
        }
        return (
            <div>
                <Header />
                <div className="d-flex divLogin">
                    <div className="divLeft">
                        {/* <h1 className="titleLogin">{Trad("Créer un nouveau mot de passe")}</h1> */}
                        <div className="messErrorLogin">
                            <div className="d-flex">
                                <div>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </div>
                                <div className="textMessError">
                                    &nbsp; &nbsp; {Trad("txt_email_pass_error")}
                                </div>
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                                passwordConf: localStorage.getItem('password') ? localStorage.getItem('password') : '',
                            }}
                            onSubmit={this.handleSubmit}
                            render={(formRenderProps: any) => (
                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>            
                                        {this.createField('password','txt_pass_label','password',this.handleChange,mdpValidator)}
                                        <PasswordStrengthBar 
                                        barColors={['#ddd','#ef4836', '#747744','#f6b44d', '#25c281']} 
                                        scoreWords={['Faible','Faible', 'moyen','Bon', 'Excellent']} 
                                        shortScoreWord="trop court" 
                                        password={this.state.password}
                                        minLength={6} 
                                        />
                                        {this.createField('passwordConf','txt_confirm_pass','password',this.handleChange)}

                                        <div className="messErrorMdp">
                                            {Trad("txt_pass_match_err")}
                                        </div>
                                        <div className="divInscSubmit">
                                            <button name="btnSubmit" type={'submit'} disabled={!formRenderProps.allowSubmit} className="btnSubmitLogin">{Trad("Modifier")}</button>
                                        </div>
                                    </fieldset>
                                </FormElement>
                            )}
                        />
                    </div>
                    <div className="divRight">
                        <img className="imgLogin" src={imgLogin} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}