import React, { Component } from 'react';
import Header from './Header';
import { Card, CardTitle, CardBody, CardActions } from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import ButtonContinue from './ButtonContinue';
import ButtonPrev from './ButtonPrev';
import $ from "jquery";
import { Redirect } from 'react-router-dom';

import '../css/audience.css';
import axios from 'axios';
import { Trad } from '../trad-lib/index.bin';
import ReactLoader from './ReactLoader';

export default class Audience extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props : any){
        super(props);
        this.state = {
            cibleChecked: JSON.parse(localStorage.getItem("cibleSelect") || '[]'),
            nbCible: 0,
            btnDisabled: false,
            listCible: [],
            isLoading:true,
        }
    }

    onClickCard(id : any){
        if($('#'+id+' .k-card').hasClass('cardSelected')){
            $('#'+id+' .k-card').removeClass('cardSelected');
            $('#'+id+' .k-card .iconAudience').removeClass('iconAudienceSelected');
        } else {
            $('#'+id+' .k-card').addClass('cardSelected');
            $('#'+id+' .k-card .iconAudience').addClass('iconAudienceSelected');
        }
        let cibleChecked = this.state.cibleChecked;
        if(cibleChecked.includes(id)){
            let index = cibleChecked.indexOf(id);
            cibleChecked.splice(index, 1);
        } else {
            cibleChecked.push(id);
        }
        const nbCible = cibleChecked.length;

        this.setState({
            cibleChecked: cibleChecked,
            nbCible: nbCible,
        },()=>{
            localStorage.setItem("cibleSelect",JSON.stringify(cibleChecked))
            localStorage.removeItem('packages')
        })
    }

    componentDidMount(){
        var start_time = new Date().getTime();

        const data = {
            "filter": {
                "PRM_LIB_LIKE": "%%",
                "PRM_LIST_ID": null,
                "PRM_PUBLIABLE_BRIEF": 1,
                "PRM_VALIDE": 1,
                "NoLinks": 0
            }
        }
        axios
        .post(`${this.baseUrl}/cible`,data)
        .then((response) => {
          if(response.data.error){
            // console.log(response)
          }else{
              localStorage.setItem('listCible',JSON.stringify(response.data.data.GetManyResult));
              this.setState({
                  listCible: JSON.parse(localStorage.getItem('listCible') || '[]'),
                  isLoading:false
              })
            const tabCibleSelect = this.state.cibleChecked;
            tabCibleSelect.forEach((element : any) => {
                $('#' + element + ' .k-card').addClass('cardSelected');
                $('#' + element + ' .k-card .iconAudience').addClass('iconAudienceSelected');
            });
            var request_time = new Date().getTime() - start_time;
            // console.log(request_time);
          }

         }).catch((err) => console.log(err.message));
        const tabCibleSelect = this.state.cibleChecked;
        const nbCible = tabCibleSelect.length;
        this.setState({
            nbCible: nbCible,
        })
    }

    render() {
        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        const cibles = this.state.listCible;
        // console.log(this.state.isLoading)
        return (
            <div>
                <Header />
                <div className="paddingBody heightTot">
                    <div>
                        <div className="divTitle">
                            <p className="pResumePack">Audience</p>
                            <h1 className="titleResumePack">{Trad("txt_audience_h")}</h1>
                        </div>
                        <div>
                            <div className="d-flex">
                                <div className="w-50 divTitleCible">
                                {
                                    cibles.length > 0 && !this.state.isLoading  ?
                                    Trad("txt_audience_h2")
                                    :
                                    Trad("Pas de cibles disponible")
                                }
                                </div>
                                <div className="w-50 text-right divNbCible">
                                    {
                                        this.state.nbCible > 1 ?
                                            this.state.nbCible +' ' + Trad("txt_selected_p")
                                        :
                                            this.state.nbCible +' ' + Trad("txt_selected_s")
                                    }
                                </div>
                            </div>
                            {
                                this.state.isLoading ?
                                <ReactLoader /> 
                                :'' 
                            }
                            <div className="row mt-4">
                                {
                                    cibles.map((cible : any , key : number) => (
                                        <div id={cible.CMKT_ID} key={key} className="column">
                                            <button onClick={() => this.onClickCard(cible.CMKT_ID)} className="btnCard">
                                                <Card className="cardCible">
                                                    <CardBody>
                                                        <CardTitle><FontAwesomeIcon className="iconAudience" icon={faSmile} /></CardTitle>
                                                        <p className="pCard">{cible.CMKT_LIB}</p>
                                                    </CardBody>
                                                </Card>
                                            </button>
                                        </div>
                                    ))
                                }                               
                            </div>
                        </div>
                    </div>
                    <div className="d-flex divBtnRfp">
                        <ButtonPrev redirect={"/periode"} />
                        <ButtonContinue redirect={"/home"} skip={this.state.nbCible > 0 ? false : true} onDisabled={this.state.btnDisabled}   />
                    </div>
                </div>
            </div>
        )
    }
}