import React, { Component } from 'react';
import Header from './Header';
import ButtonPrev from './ButtonPrev';
import ModalCoordonees from './ModalCoordonees';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormInput, FormTextArea } from './form-components';
import { annonceurValidator, emailValidator, firstNameValidator, lastNameValidator, societeValidator, telValidator } from './Validators';
import { Redirect } from 'react-router-dom';
import $ from "jquery";

import '../css/resumeUser.css';
import axios from 'axios';
import { Trad } from '../trad-lib/index.bin';

export default class ResumeUser extends Component<any, any> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props : any){
        super(props);
        this.onClickModalCoor = this.onClickModalCoor.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleSubmitVal = this.handleSubmitVal.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            email: JSON.parse(sessionStorage.getItem('userData')).data.email,
            firstName: JSON.parse(sessionStorage.getItem('userData')).data.firstName,
            lastName: JSON.parse(sessionStorage.getItem('userData')).data.lastName,
            gender:  localStorage.getItem('gender') ? localStorage.getItem('gender') : JSON.parse(sessionStorage.getItem('userData')).data.gender,
            telephone: JSON.parse(sessionStorage.getItem('userData')).data.telephone,
            fonction: localStorage.getItem('fonction') ? localStorage.getItem('fonction') : JSON.parse(sessionStorage.getItem('userData')).data.fonction,
            society:JSON.parse(sessionStorage.getItem('userData')).data.society,
            domain:localStorage.getItem('domain'),
            address:localStorage.getItem('address') ? localStorage.getItem('address') : JSON.parse(sessionStorage.getItem('userData')).data.address,
            postalCode:localStorage.getItem('postalCode'),
            city:localStorage.getItem('city') ? localStorage.getItem('city') : JSON.parse(sessionStorage.getItem('userData')).data.city,
            country:localStorage.getItem('country') ? localStorage.getItem('country') : JSON.parse(sessionStorage.getItem('userData')).data.country,
            countries: [],
            annoncer:localStorage.getItem('annoncer'),
            showModal: false,
            userData: JSON.parse(sessionStorage.getItem('userData') || '[]'),
            redirect: false,
            created: false
        };
    }
    onClickModalCoor(){
        this.setState({
            showModal: true
        });
    }
    handleModalShow(){
        this.setState({
            showModal: false,
            userData: JSON.parse(sessionStorage.getItem('userData') || '[]'),
        });
    }
    handleSubmitVal(data:any){
        let rfi = JSON.parse(localStorage.getItem('rfi') || '[]');
        const ciblesIds = JSON.parse(localStorage.getItem('cibleSelect') || '[]')
        const cibleList = JSON.parse(localStorage.getItem('listCible') || '[]')
        const cibles = cibleList.filter(f => ciblesIds.some(item => item === f.CMKT_ID)).map((cible)=> cible.CMKT_LIB).toString();
        data.country = localStorage.getItem('country') !== JSON.parse(sessionStorage.getItem('userData')).data.country ? localStorage.getItem('country') : JSON.parse(sessionStorage.getItem('userData')).data.
        data.gender = localStorage.getItem('gender') !== JSON.parse(sessionStorage.getItem('userData')).data.gender ? localStorage.getItem('gender') : JSON.parse(sessionStorage.getItem('userData')).data.gender
        // console.log(data)
        rfi = {...rfi, 
            annoncer : data.annoncer,
            productName : data.product,
            comment : data.comment,
            emailBrief : localStorage.getItem('emailBrief'),
            cibles : cibles,
            data : data
        }
        axios
        .post(`${this.baseUrl}/rfi`, rfi)
        .then((response) => {
          if(response.data.error){
            console.log(response)
          }else{
            this.setState({
                created: true
            });
          }

         }).catch((err) => console.log(err.message));
        this.setState({
            redirect: true
        });
    }
    handleChange(event: any) {
        const target = event.target as any;
        // console.log(target.name,target.value);
        this.setState({ [target.name]: target.value },()=>{
            localStorage.setItem(target.name, this.state[target.name])
        });
    }

    async componentDidMount(): Promise<void> {
        $('.k-form').addClass('heightTot');

        const filter =         {
            "filter": {
                "PRM_VALIDE": 1,
                "NoLinks": 1
            }
        }
        await axios.post(`${this.baseUrl}/country`,filter)
        .then((response: any) => {
            if(response.data.error){
            }else{   
            const allCountries = response.data.data?.GetManyResult;
            localStorage.setItem('allCountries', JSON.stringify(allCountries));
            this.setState({
                countries:allCountries
            })
            }
        }).catch((err) => {console.log(err.message)});
    }
    createField(fieldName:string,label:string,type:any,dvalue:any,handle:any,validator?:any,option?:any){
        return (
            <div className="form-group registerForm">
                <label htmlFor={fieldName} className="">{Trad(label)}
                {
                    option ? 
                    <span className="optionText">{option}</span>
                    :
                    <span className="text-danger"> *</span>
                }
                </label>
                 <Field
                    id={fieldName}
                    name={fieldName}
                    className={'form-control'}
                    type={type}
                    placeholder={Trad(label)}
                    component={FormInput}
                    // defaultValue={dvalue}
                    onChange={handle}
                    validator={validator}
                />
            </div>
            )
    }
    render() {
        const user = JSON.parse(sessionStorage.getItem('userData')).data;
        this.state.countries.sort((a:any, b:any) => a.PAYS_LIB.localeCompare(b.PAYS_LIB));

        if (!sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        if(this.state.redirect){
            const path = window.location.pathname;
            const rediPath = "/" + path.split('/')[1] + "/validation";
            return (<Redirect to={{pathname:rediPath}}/>)
        }
        return (
            <div>
                <ModalCoordonees showModal={this.state.showModal} onCurrentModalShow={this.handleModalShow} />
                <Header />
                <div className="paddingBody">
                    <Form
                        initialValues={{
                            email: this.state.email,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            gender: this.state.gender,
                            telephone: this.state.telephone,
                            fonction: this.state.fonction,
                            society: this.state.society,
                            domain: this.state.domain,
                            address: this.state.address,
                            city: this.state.city,
                            country: this.state.country,
                            annoncer:this.state.annoncer
                        }}
                        onSubmit={this.handleSubmitVal}
                        render={(formRenderPropsVal: any) => (
                            <FormElement>
                                <div className="divTitle">
                                    <p className="pResumePack">{Trad("resume")}</p>
                                    <h1 className="titleResumePack">{Trad("resume_user_h1")}</h1>
                                </div>
                                <div className="d-flex divUserRes">
                                    <div className="w-50 marginRight">
                                        <fieldset className={'k-form-fieldset mt-0'}>
                                            {this.createField('annoncer','Annonceur','text','',this.handleChange,annonceurValidator)}
                                            {this.createField('product','Produit','text','',this.handleChange,'',' ')}
                                            <Field
                                                id={'commentaires'}
                                                name={'comment'}
                                                label={Trad("Commentaire")}
                                                placeholder={Trad("Commentaire")}
                                                component={FormTextArea}
                                                onChange={this.handleChange}
                                            />              
                                        </fieldset>
                                    </div>
                                    <div className="w-50 marginLeft d-table">
                                    <fieldset className={'k-form-fieldset'}>
                                        <div className="form-row" onChange={this.handleChange.bind(this)}>
                                            <div className="col">
                                                <input id="female" checked={this.state.gender === "F"} className="radiBox" type="radio" value="F" name="gender"/>
                                                <label htmlFor="female" className="radioLabel">{Trad('Madame')}</label>
                                            </div>
                                            <div className="col">
                                                <input id="male" checked={this.state.gender === "M"} className="radiBox" type="radio" value="M" name="gender"/>
                                                <label htmlFor="male" className="radioLabel">{Trad('Monsieur')}</label>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                {this.createField('lastName','Nom','text',this.state.lastName,this.handleChange,lastNameValidator)}
                                            </div>
                                            <div className="col">
                                                {this.createField('firstName','Prénom','text',this.state.firstName,this.handleChange,firstNameValidator)}
                                            </div>
                                        </div>

                                        {this.createField('email','Adresse e-mail','email',this.state.email,this.handleChange,emailValidator)}
                                        <div className="messErrorEmail">
                                            {Trad("txt_email_exist_err")}
                                        </div>

                                        {this.createField('telephone','Téléphone','number',user.telephone,this.handleChange,telValidator)}

                                        {this.createField('society','Societé','text','',this.handleChange,societeValidator)}
                                        {this.createField('fonction','Fonction','text','',this.handleChange,'',' ')}

                                        {this.createField('domain','Secteur','text','',this.handleChange,'',' ')}
                                        {this.createField('address','Adresse','text','',this.handleChange,'',' ')}
                                        {this.createField('city','Ville','text','',this.handleChange,'',' ')}

                                        <div className="form-group ">
                                        <label htmlFor={'country'} className="">{Trad('Pays')}
                                                <span className="text-danger"> *</span>
                                            </label>
                                        <select id='country' name="country" value={this.state.country} onChange={this.handleChange} className="form-select form-control" aria-label="Default select example">
                                            {
                                                this.state.countries.map((counrty:any)=>(
                                                    <option value={counrty.PAYS_CODE}>{counrty.PAYS_LIB.charAt(0).toUpperCase() + counrty.PAYS_LIB.slice(1).toLowerCase()}</option>
                                                ))
                                            }
                                        </select>
                                        </div>
                                    </fieldset>
                                    </div>
                                </div>
                                <div className="d-flex divBtnRfp">
                                    <ButtonPrev redirect={"/package/resume"} />
                                    <div className="divBtn">
                                        <button className="btnSuiv completed " type={'submit'} >{Trad("Envoyer")} &nbsp; <FontAwesomeIcon className="iconCont" icon={faCheck} /></button>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            </div>
        )
    }
}