import axios from "axios";
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import imgLogin from '../images/arbre.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Trad, TradProvider } from '../trad-lib/index.bin'
import $ from "jquery";
import Header from './Header';

import '../css/login.css';

interface Props {}
interface State {
    email:string,
    password:string,
    isSubmitting: boolean,
    redirect: boolean
}
export default class Login extends Component<Props, State> {
    private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

    constructor(props:any){
        super(props)
        this.state = {
            email:'',
            password:'',
            isSubmitting: false,
            redirect: false
        }
        this.handleChange = this.handleChange.bind(this);

    }
    handleChange(event: any) {
        const target = event.target as any;
        this.setState({ [target.name]: target.value } as State);
    }
    handleSubmit = (event:any) => {
        const url = `${this.baseUrl}/auth/login`
        event.preventDefault();
        this.setState({ isSubmitting: true });
        const state = this.state;
        axios
        .post(url,state)
        .then((response) => {
          if(response.data.error){
              $('.messErrorLogin').css('display', 'block');
          }else{
            // console.log(response.data)
            sessionStorage.setItem('userData',JSON.stringify(response.data));
            this.setState({
                redirect: true
              });
          }
         }).catch((err) => 
            $('.messErrorLogin').css('display', 'block')
        ); 
      };
    render() {
        if (sessionStorage.getItem('userData')){
            return (<Redirect to={'/country'}/>)
        }
        return (
            <div>
                <Header />
                <div className="d-flex divLogin">
                    <div className="divLeft">
                        <h1 className="titleLogin">{Trad("txt_login_h")}</h1>
                        <div className="messErrorLogin">
                            <div className="d-flex">
                                <div>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </div>
                                <div className="textMessError">
                                    &nbsp; &nbsp; {Trad("txt_email_pass_error")}
                                </div>
                            </div>
                        </div>
                        <form className="formLogin" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>{Trad("txt_email_label")}</label>
                                <input 
                                    type="email" 
                                    className="form-control inputLogin" 
                                    name="email" 
                                    placeholder={Trad("txt_email_label")} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>{Trad("txt_pass_label")}</label>
                                <input 
                                    type="password" 
                                    className="form-control inputLogin" 
                                    placeholder={Trad("txt_pass_label")} 
                                    name="password"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group d-flex mb-3">
                                <div className="custom-control custom-checkbox divCheck">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label labelRemen" htmlFor="customCheck1">{Trad("txt_remember")}</label>
                                </div>
                                <div className="divForgotPassword">
                                    <Link className="LienForgot" to="/forgetpass">{Trad("txt_forgot_pass")}</Link>
                                </div>
                            </div>
                            <button name="btnSubmit" onSubmit={this.handleSubmit} className="btnSubmitLogin">{Trad("txt_login_h")}</button>
                        </form>
                        <div className="divBottom">
                            <hr/>
                            <div className="divInscri">
                                {Trad("txt_login_footer")} &nbsp; <a className="linkInscri" href="/inscription">{Trad("txt_register")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="divRight">
                        <img className="imgLogin" src={imgLogin} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}