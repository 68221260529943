import React, { Component } from 'react'
import TradSelect from './TradSelect';
import StepperLine from './StepperLine';
import imgCanal from '../images/canalAd.png';
import { Redirect } from 'react-router-dom';

import '../css/header.css';

export default class Header extends Component<any, any> {
    constructor(props : any){
        super(props);
        this.onClickNew = this.onClickNew.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            redirect: false,
            redirectPath: "",
        }
    }
    
    onClickNew(){
        if(window.location.pathname !== "/country"){
            localStorage.removeItem('cStartDate');
            localStorage.removeItem('cEndDate');
            localStorage.removeItem('cibleSelect');
            localStorage.removeItem('formatSelected');
            localStorage.removeItem('joursSelected');
            localStorage.removeItem('trancheSelected');
            localStorage.removeItem('tabSuppSelected');
            localStorage.removeItem('selectedPackage');
            localStorage.removeItem('rfi');
            localStorage.removeItem('priceTot');
            localStorage.removeItem('devise');
            this.setState({
                redirect: true,
                redirectPath: "/country"
            });
        }
    }
    logout(){
        sessionStorage.removeItem('userData');
        localStorage.clear()
        this.setState({
            redirect: true,
            redirectPath: "/"
        });
    }
    render() {
        if(this.state.redirect){
            return (<Redirect to={this.state.redirectPath}/>)
        }
        return (
            <div className="divHeader">
                <div className="margeHeader d-flex">
                    <div className="divBtnHome">
                        <button onClick={this.onClickNew} className="btnImageCanal"><img className="imgCanalAd" src={imgCanal} alt="Canal +"/></button>
                    </div>
                    <div className="divStepper"> 
                        <StepperLine />
                    </div>
                    <div className="divTrad">
                        <TradSelect />
                    </div>
                    {
                        sessionStorage.getItem('userData') ?
                        <div className="logout">
                            <button onClick={this.logout} className="btn btn-danger">Logout</button>
                        </div>
                        :''

                    }
                </div>
            </div>
        )
    }
}